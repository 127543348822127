/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import { css } from "@emotion/core"

const containerStyle = css`
  margin: 0 auto;
  width: 60%;
`

const footerStyle = css`
  text-align: center;
  margin-top: 2em;
  font-size: 0.9em;
  color: #999;
`

const Layout = ({ children }) => {
  return (
    <div css={containerStyle}>
      <main>{children}</main>
      <footer css={footerStyle}>
        © {new Date().getFullYear()} omcode, Inc. All rights reserved.
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
